<template>
	
	<div class="">
		<div class="PC-BOX  xl:block l:block xm:hidden md:hidden">
			<div class="team-card-head">
				<div class="left">
					<template v-if="teamType == 0">
						Team You Joined
					</template>
					<template v-else>
						Team You Created
					</template>
				</div>
				<div class="right flex">
					<template v-if="myTeam['invcode']">
						<div class="right-1 flex">
							<span>Invite Code :</span>
							<span>{{myTeam['invcode']}}</span>
							<a-icon type="copy" style="font-size: 16px; color: #fff;" v-copy="myTeam['invcode']" />
						</div>
						<div class="right-2 flex">
							<dl v-copy="'https://www.wod.ai/InvLogin?inviteCode='+myTeam['invcode']">
								<dt></dt>
								<dd>Copy invite link</dd>
							</dl>
							<dl class="flex" @click="opentw">
								<dt><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/twitter-icon1.png"
										alt="" /></dt>
								<dd>Post a tweet</dd>
							</dl>
						</div>
					</template>
					
					
					<template v-else>
						<template v-if="teamType == 0">
							<a-button type="primary" @click="$router.push('invLogin')">
								Join a Team
							</a-button>
						</template>
						<template v-else>
							<a-button type="primary" @click="createTeam()">
								Create a Team
							</a-button>
						</template>
					</template>
				</div>
			</div>
			<template v-if="myTeam['invcode']">
				<div class="team-cardcontent flex-between">
					<div class="left flex-between">
						<div class="progress flex">
							<div class="ranking">
								<dl class="ranking-box flex-column flex-center">
									<dt>{{ranking}}</dt>
									<dd>Ranking</dd>
								</dl>
							</div>
							<div class="ranking-right ">
								<span>{{$Vteamcard("TotalPoi_2d56")}}</span>
								<span>{{myTeam['teamscore']}}</span>
							</div>
						</div>
						<div class="left-1 flex-around">
							<div class="team-name">
								<dl>
									<dt>Team Name:</dt>
									<dd class="flex ">
										<span>{{myTeam['teamname']}}</span>
										<a-icon type="edit" v-if="myTeam['renamed']<=0 && teamType == 1" @click="teamNameShow = true" />
									</dd>
								</dl>
							</div>
							<dl class="team-size">
								<dt>Team Size:</dt>
								<dd class="flex">
									<span>{{myTeam['membercount']}}</span>
									<span>/25</span>
								</dd>
							</dl>
						</div>
					</div>
					<div class="right">
						<div class="btn-box flex-column flex-between">
							<template v-if="teamType != 0"
								:class="{'acquisition':sbtMedal['starstuddedcaption'] !='0'}">
								<a-button type="primary" @click="$router.push('InviteTeam')">
									<span>Claim Star-Studded Captain Medal</span>
								</a-button>
							</template>
							<a-button @click="$router.push('/allMedalWall?id=honorableteam')" type="primary"
								:class="{'acquisition':sbtMedal['honorableteam'] !='0'}">
								<span>Claim Honorable Team Medal</span>
							</a-button>
							<a-button type="primary" @click="$router.push('/allMedalWall?id=outstandingteam')"
								:class="{'acquisition':sbtMedal['outstandingteam'] !='0'}">
								<span>Claim Outstanding Team Medal</span>
							</a-button>
						</div>
					</div>
				</div>

				<div class="list-box" :class="listShow?'list-show':''">

					<div class="title" @click="listShow = !listShow">
						<a-icon type="up" />
						<p>See team details </p>
						<a-icon type="down" />
					</div>
					<div class="ul-box">
						<ul>
							<div class="ul-head ">
								<span>Ranking</span>
								<span>{{$Vteamcard("Member’s_4681")}}</span>
								<span>Points Earned</span>
							</div>
							<li class="" v-for="(item,idx) in teammember" :key="idx">
								<span>{{idx+1}}</span>
								<span>Captain {{item.useraddress | addressFilter }}</span>
								<span>{{item.totalscore | formatMoney}}</span>
							</li>
						</ul>
						<p>
							<mPagination :pageCount="pageCount" @onChange="onChange" />
						</p>
					</div>
				</div>
			</template>

			
			<template v-else>
				<p class="no-list" v-if="teamType == 0">
					You're not currently on any team.
				</p>
				<p class="no-list" v-else>
					You're not currently creating any team.
				</p>
			</template>

		</div>
		<div class="H5-BOX  xl:hidden l:hidden xm:block md:block">
			<div class="team-card-head">
				<div class="left">
					<template v-if="teamType == 0">
						Team You Joined
					</template>
					<template v-else>
						Team You Created
					</template>
				</div>
			</div>

			<template v-if="myTeam['invcode']">
				<div class="team-cardcontent ">
					<div class="progress flex">
						<div class="ranking">
							<dl class="ranking-box flex-column flex-center">
								<dt>{{ranking}}</dt>
								<dd>Ranking</dd>
							</dl>
						</div>
						<div class="ranking-right ">
							<span>{{$Vteamcard("TotalPoi_2d56")}}</span>
							<span>{{myTeam['teamscore']}}</span>
						</div>
					</div>
					<div class="team-name flex-between">
						<dl class="flex">
							<dt>Team Name:</dt>
							<dd>{{myTeam['teamname']}}</dd>
						</dl>
						<span>Change team name</span>
					</div>
					<dl class="team-size flex">
						<dt>Team Size:</dt>
						<dd class="flex">
							<span>{{myTeam['membercount']}}</span>
							<span>/25</span>
						</dd>
					</dl>


					<div class="top-right flex-between">
						<div class="right-1 flex">
							<span>Invite Code :</span>
							<span>{{myTeam['invcode']}}</span>
							<a-icon type="copy" style="font-size: 16px; color: #fff;" v-copy="myTeam['invcode']" />
						</div>
						<div class="right-2 flex-column">
							<dl class="flex-center"
								v-copy="'https://www.wod.ai/InvLogin?inviteCode='+myTeam['invcode']">
								<dt></dt>
								<dd>Copy invite link</dd>
							</dl>
							<dl class="flex-center" @click="opentw">
								<dt><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/twitter-icon1.png"
										alt="" /></dt>
								<dd>Post a tweet</dd>
							</dl>
						</div>
					</div>

					<div class="list-box" :class="listShow?'list-show':''">
						<div class="title" @click="listShow = !listShow">
							<a-icon type="up" />
							<p>See team details</p>
							<a-icon type="down" />
						</div>
						<div class="ul-box">
							<ul>
								<div class="ul-head ">
									<span>Ranking</span>
									<span>{{$Vteamcard("Member’s_4681")}}</span>
									<span>Points Earned</span>
								</div>
								<li class="" v-for="(item,idx) in teammember" :key="idx">
									<span>{{idx+1}}</span>
									<span>Captain {{item.useraddress | addressFilter }}</span>
									<span>{{item.totalscore | formatMoney}}</span>
								</li>
							</ul>
							<p v-if="teammember.length>0">
								<mPagination :pageCount="pageCount" @onChange="onChange" />
							</p>
						</div>

					</div>

				</div>
			</template>


			
			<template v-if="!myTeam['invcode']">
				<p class="no-list" v-if="teamType == 0">
					You're not currently on any team.
				</p>
				<p class="no-list" v-else>
					You're not currently creating any team.
				</p>
			</template>

			<div class="btn-box-card flex-column flex-between">
				<template v-if="!myTeam['invcode']">
					
					
					<template v-if="teamType == 0">
						<a-button type="primary" @click="$router.push('invLogin')">
							Join a Team
						</a-button>
					</template>
					<template v-else>
						<a-button type="primary" @click="createTeam()">
							Create a Team
						</a-button>
					</template>
				</template>


				<template v-else>
					<template v-if="teamType != 0">
						<a-button :class="{'acquisition':sbtMedal['starstuddedcaption'] !='0'}" type="primary"
							@click="$router.push('/InviteTeam')">
							<span>Claim Star-Studded Captain Medal</span>
						</a-button>
					</template>
					<a-button type="primary" :class="{'acquisition':sbtMedal['honorableteam'] !='0'}">
						<span>Claim Honorable Team Medal</span>
					</a-button>
					<a-button type="primary" :class="{'acquisition':sbtMedal['outstandingteam'] !='0'}">
						<span>Claim Outstanding Team Medal</span>
					</a-button>
				</template>
			</div>

		</div>



		<modal class="set-team-name" v-model="teamNameShow">
			<div class="set-team-name-box">
				<h1>Congratulations</h1>
				<h2>You are now eligible to create your own team. Please change your team name</h2>
				<div class="input-box">
					<input type="text" v-model="teamName" placeholder="Please enter a team name" />
				</div>
				<div class="btn-box flex-column">
					<a-button type="primary" @click="changeName" :loading="nameLoading">
						<span @click="changeName">Save changes</span>
					</a-button>
					<a-button type="primary" @click="teamNameShow = false">
						<span>Rename later</span>
					</a-button>
				</div>
			</div>
		</modal>


		
		<modal class="noBridge-box" v-model="noBridge">
			<div class="noBridge">
				<p>
					<span class="text-gradient">Bridge</span>
					<span>your tokens to create a team!</span>
				</p>
				<a-button type="primary" @click="createTeam()">
					Go to Bridge
				</a-button>
			</div>
		</modal>
		<modal class="fsTwitter-box" v-model="fbTwitter">
			<div class="noBridge">
				<p>
					<span class="text-gradient">Post a tweet</span>
				</p>
				<div class="flex-between flex-end" style="align-items: flex-end;">
					<a-textarea :maxLength="280" :value="wtStr" v-model="wtStr2" placeholder="Please edit your posts" :auto-size="true" />
					<span style="padding-bottom: 10px;padding-left: 5px;">{{wtStr2.length}}/280</span>
				</div>
				
				
			</div>

			<a-button type="primary" @click="twStAuth">
				<div class="flex-center">
					<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/twitter-icon1.png" alt="" />
					<span>Post a tweet</span>
				</div>
			</a-button>

		</modal>
	</div>

</template>

<script>
	import mPagination from '@/components/m-pagination/m-pagination.vue'
	import { getSign } from '@/web3utils/base/index.js'
	import { renameteam, getteaminfo, createTeam, getteamlist, getteammember, getbstassert } from '@/utils/active.api'
	import { twStAuth } from '@/utils/twitter.api'
	import modal from '@/components/modal.vue'
	import { mapState } from 'vuex'
	import { error } from 'autoprefixer/lib/utils'
	
    /* viai18n-disable */
    import $VteamcardMessages from "./team-card.messages.json";
  const $defaultObject = {
		name: 'Name',
		components: { modal, mPagination },
		data() {
			return {

				fbTwitter: false,
				teamNameShow: false,
				nameLoading: false,
				teamName: '',

				myTeam: {},

				pageCount: 1,
				pageIndex: 1,
				teammember: [],

				listShow: false,
				noBridge: false,
				ranking: 0,

				wtStr2: '',

			}
		},
		computed: {
			...mapState(['addressinfo', 'address', 'sbtMedal']),
			teamType() {
				if (this.teamleader != this.address) {
					return 0
				}
				return 1
			},
			wtStr() {
				return
			}
		},
		props: {
			teamleader: {
				type: String,
				default: ''
			},
		},
		watch: {
			address(newValue, oldValue) {
				this.init();
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			opentw() {
				this.wtStr2 = `🚀 Hey Crypto Fam! 🌐 Just scored an awesome airdrop opportunity with BP-FLAC! @wod_global🎁
💰 Bringing my assets over and forming a squad for EXTRA rewards.🚀
🔗Join me! Use my invite link https://www.wod.ai/InvLogin?inviteCode=${this.myTeam.invcode} & code ${this.myTeam.invcode} to level up together.✨ `
				this.fbTwitter = true;
			},
			// 修改团队名称
			async changeName() {
				if (!this.teamName) {
					return this.$message.error('Please enter a team name')
				}
				const signmsg = await getSign('RENAMETEAM', this.address)
				if (!signmsg) return this.$message.error('Failed to request signature. Please try again')
				this.nameLoading = true;
				const { success, msg } = await renameteam({
					teamname: this.teamName,
					signmsg: signmsg
				})
				this.nameLoading = false;
				if (success) {
					this.$message.success(msg);
					this.teamNameShow = false;
					this.init();
				} else {
					this.$message.error('You are not a team leader');
				}
			},
			async init() {
				//第二个组件延迟请求
				if (this.teamType != 0) {
					await this.$sleep(200)
				}
				if (this.teamleader) {
					const { success, data, msg } = await getteaminfo(this.teamleader)
					if (success) {
						this.ranking = msg;
						this.myTeam = data
					}
				}
				this.getteammember();
			},

			//发布推特
			async twStAuth() {
				if (!this.wtStr2) {
					return this.$message.error("Please edit your posts")
				}
				const res = await twStAuth(this.wtStr2);
				window.open(res.data.url);
			},
			onChange(val) {
				this.pageIndex = val;
				this.getteammember();
			},
			//团队成员
			async getteammember() {
				if (!this.teamleader) return
				const { success, list, pageCount } = await getteammember({
					teamleader: this.teamleader,
					pageSize: 5,
					pageIndex: this.pageIndex,
				})
				this.pageCount = pageCount
				this.teammember = list
			},
			// 创建团队
			async createTeam() {
				if (this.noBridge) {
					return window.open('https://bridge.wodrpc.org/#/index')
				}
				// if()
				const signmsg = await getSign('CREATETEAM', this.address)
				if (!signmsg) return this.$message.error('Failed to request signature. Please try again')
				const { success, msg } = await createTeam({
					teamname: 'Team' + this.generateRandomNumber(),
					signmsg: signmsg
				})
				if (success) {
					this.init()
				} else {
					this.noBridge = true;
					this.$message.error(msg)
				}
				// 
			},
			generateRandomNumber() {
				var randomNumber = Math.floor(Math.random() * 900000) + 100000;
				return randomNumber;
			},
		}
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $Vteamcard(key,origin){
      const messages = $VteamcardMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $Vteamcard(key,origin){
      const messages = $VteamcardMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
	.PC-BOX {
		margin-bottom: 20px;
		padding: 40px 40px 0;
		border-radius: 20px;
		box-sizing: border-box;
		border: 1px solid rgba(255, 255, 255, 0.20);
		border-top: 1px solid rgba(255, 255, 255, 0.05);
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		background: linear-gradient(0deg, rgba(215, 204, 246, 0.20) -8.91%, rgba(19, 8, 48, 0.00) 45.33%), radial-gradient(75.76% 75.76% at 50% 10.33%, rgba(0, 0, 0, 0.20) 0%, rgba(4, 1, 19, 0.00) 39.26%, rgba(61, 84, 202, 0.20) 100%);

		.team-card-head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			.left {
				color: #FFF;

				font-size: 40px;
				font-style: normal;
				font-weight: 590;
				line-height: 40px;
				/* 100% */
				letter-spacing: 0.4px;
			}

			.right {
				.right-1 {
					span:nth-of-type(1) {
						color: #AFAFAF;

						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					span:nth-of-type(2) {
						padding-left: 20px;
						padding-right: 5px;
						color: #FFF;
						font-feature-settings: 'clig' off, 'liga' off;

						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}

				.right-2 {
					padding-left: 20px;

					dl {
						cursor: pointer;
						margin: 0;
						padding: 4px 20px;
						border-radius: 100px;
						background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.24) 100%);

						dt {
							img {
								width: 16px;
								height: 16px;
							}
						}

						dd {
							color: #FFF;
							font-feature-settings: 'clig' off, 'liga' off;

							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							margin: 0;
						}
					}

					dl:nth-of-type(2) {
						margin-left: 10px;
					}
				}
			}
		}

		.team-cardcontent {
			padding: 60px 0;
			flex-wrap: wrap;

			.left {
				padding: 20px;
				flex: 1;
				border-radius: 12px;

				flex-wrap: wrap;

				position: relative;

				&::after {

					content: "";
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: 0;
					border-radius: 12px;
					opacity: 0.9;
					background: linear-gradient(0deg, rgba(215, 204, 246, 0.40) -8.91%, rgba(19, 8, 48, 0.00) 45.33%),
						radial-gradient(75.76% 75.76% at 50% 10.33%, rgba(0, 0, 0, 0.40) 0%, rgba(4, 1, 19, 0.00) 39.26%, rgba(61, 84, 202, 0.40) 100%);
					// background: radial-gradient(89.29% 89.29% at 49.94% 15.38%, #000000 0%, rgba(4, 1, 19, 0) 39.95%, #ca9f3dbd 100%),
					// 	linear-gradient(0deg, #2E2A41, #2E2A41),
					// 	linear-gradient(0deg, #F6EACC -5.36%, rgba(48, 10, 8, 0) 54.53%);
				}

				&::before {
					content: "";
					display: block;
					position: absolute;
					z-index: 0;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: 0;
					background-image: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/team-card-bg.jpg');
					border-radius: 12px;
					opacity: 0.1;
				}

				.progress {
					position: relative;
					z-index: 2;
					flex: 1;
					margin-right: 20px;
					max-width: 540px;

					.ranking {
						width: 106px;
						height: 106px;
						flex-shrink: 0;
						/* 06dp */

						background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/Base.png') no-repeat;
						background-size: 100% 100%;
						position: relative;
						z-index: 9;

						.ranking-box {
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							width: 69.563px;
							height: 69.563px;
							flex-shrink: 0;
							background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/baseimg.png') no-repeat;
							background-size: 100% 100%;
							margin: 0;

							dt {
								padding-top: 10px;
								text-align: center;
								font-feature-settings: 'clig' off, 'liga' off;

								font-size: 24px;
								font-style: normal;
								font-weight: 700;
								line-height: 120%;
								/* 28.8px */
								text-transform: uppercase;

								background: var(--Linear, linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%));
								background-clip: text;
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}

							dd {
								color: #4A4A4A;

								font-size: 10px;
								font-style: normal;
								font-weight: 274;
								line-height: normal;
							}
						}
					}

					.ranking-right {
						margin-left: -50px;
						padding-left: 50px;
						min-width: 360px;
						max-width: 460px;
						flex: 1;
						height: 50px;
						padding-right: 8%;
						background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/ranking-right.png') no-repeat;
						background-size: 100% 100%;
						position: relative;
						z-index: 2;
						display: flex;
						align-items: center;
						justify-content: flex-end;

						&::after {
							content: "";
							display: block;
							position: absolute;
							z-index: 0;
							width: 110%;
							height: 61.677px;
							flex-shrink: 0;
							background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/ranking-right-bg.png') no-repeat;
							background-size: 100% 100%;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);

						}

						span:nth-of-type(1) {
							display: inline-block;
							color: #FFF;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}

						span:nth-of-type(2) {
							display: inline-block;
							padding-left: 11px;
							color: #FFF;
							text-align: right;
							font-size: 28px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
						}
					}

				}

				.left-1 {
					flex: 1;

					position: relative;
					z-index: 2;
				}

				.team-name {
					margin-right: 20px;

					dt {
						color: #AFAFAF;

						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					dd {
						cursor: pointer;

						&:hover {
							.anticon-edit {
								display: inline-block;
								opacity: 1;
							}
						}

						span {

							color: #FFF;
							font-feature-settings: 'clig' off, 'liga' off;

							font-size: 36px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
						}

						.anticon-edit {
							display: none;
							transition: all .4s;
							opacity: 0;
							font-size: 24px;
							margin-top: 5px;
							margin-left: 10px;
							color: #95b0ff;
						}
					}
				}

				.team-size {
					dt {
						color: #AFAFAF;

						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					dd {
						span:nth-of-type(1) {
							color: #FFF;

							font-size: 28px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}

						span:nth-of-type(2) {
							color: #959595;

							font-size: 28px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
					}
				}


			}

			.right {
				margin-left: 20px;

				.btn-box {
					.ant-btn {
						box-sizing: border-box;

						height: 46px;
						line-height: unset;

						border: unset;
						border-radius: 12px;
						background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);

						span {
							display: inline-block;
							min-width: 340px;
						}
					}

					.ant-btn+.ant-btn {
						margin-top: 30px;
					}
				}

			}
		}

		.list-box {
			transition: all .2s;
			border: 1px solid rgba(255, 255, 255, 0);

			.title {
				border-top: 1px solid rgba(255, 255, 255, 0.12);
				padding: 18px 0 28px;
				text-align: center;
				position: relative;
				cursor: pointer;

				p {
					margin: 0;
					color: #FFF;
					font-feature-settings: 'clig' off, 'liga' off;

					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}

				.anticon {
					position: absolute;
					left: 50%;
				}

				.anticon-up {
					top: 8px;
					display: none;
				}

				.anticon-down {
					bottom: 8px;
				}

			}

			.ul-box {
				height: 0px;
				padding: 0;
				overflow: hidden;
				transition: all .2s;

				ul {
					margin: 0;
					border-top: 1px solid rgba(255, 255, 255, 0);
					border-bottom: 1px solid rgba(255, 255, 255, 0);
					height: 320px;

					.ul-head {
						display: grid;
						grid-template-columns: 1fr 3fr 1fr;
						text-align: center;

						span {
							color: #E8E8E8;

							font-size: 24px;
							font-style: normal;
							font-weight: 274;
							line-height: normal;
						}
					}

					li:nth-of-type(1) {
						span {
							background: var(--Linear, linear-gradient(131deg, #C4923E -1.25%, #E4BE68 28.42%, #F8DA83 53.61%, #FEE07E 63.92%, #F3C433 82.64%, #E0AE2D 93.52%));
							background-clip: text;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
						}
					}

					li {
						display: grid;
						grid-template-columns: 1fr 3fr 1fr;
						text-align: center;
						margin: 16px 0;

						span {
							color: #E8E8E8;
							text-align: center;

							font-size: 24px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
					}
				}

				&>p {
					margin: 0;
					padding: 12px;
					border-top: 1px solid rgba(255, 255, 255, 0.12);
					display: flex;
					justify-content: flex-end;

				}
			}

		}

		.list-show {
			margin-bottom: 40px;
			border-radius: 15px;
			backdrop-filter: blur(10px);
			border: 1px solid rgba(255, 255, 255, 0.2);
			background: rgba(255, 255, 255, 0.04);
			box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.20);

			.title {
				border: none;
				padding: 28px 0 18px;

				.anticon-up {
					display: block;
				}

				.anticon-down {
					display: none;
				}
			}

			.ul-box {
				height: 370px;

				ul {
					border-top: 1px solid rgba(255, 255, 255, 0.12);
					border-bottom: 1px solid rgba(255, 255, 255, 0.12);
					padding: 40px 0;
				}
			}


		}



	}

	.H5-BOX {
		margin-bottom: 20px;
		padding: 12px 10px;
		border-radius: 20px;
		border: 1px solid rgba(255, 255, 255, 0.20);
		border-top: 1px solid rgba(255, 255, 255, 0.05);
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		background: linear-gradient(0deg, rgba(215, 204, 246, 0.20) -8.91%, rgba(19, 8, 48, 0.00) 45.33%), radial-gradient(75.76% 75.76% at 50% 10.33%, rgba(0, 0, 0, 0.20) 0%, rgba(4, 1, 19, 0.00) 39.26%, rgba(61, 84, 202, 0.20) 100%);

		.team-card-head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			.left {
				color: #FFF;

				font-size: 24px;
				font-style: normal;
				font-weight: 590;
				line-height: 40px;
				/* 166.667% */
				letter-spacing: 0.24px;
			}

			.right {
				.right-1 {
					span:nth-of-type(1) {
						color: #AFAFAF;

						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					span:nth-of-type(2) {
						padding-left: 20px;
						padding-right: 5px;
						color: #FFF;
						font-feature-settings: 'clig' off, 'liga' off;

						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}

				.right-2 {
					padding-left: 20px;

					dl {
						cursor: pointer;
						margin: 0;
						padding: 4px 20px;
						border-radius: 100px;
						border: 0.5px solid rgba(255, 255, 255, 0.20);
						background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.24) 100%);

						dt {
							img {
								width: 16px;
								height: 16px;
							}
						}

						dd {
							color: #FFF;
							font-feature-settings: 'clig' off, 'liga' off;

							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							margin: 0;
						}
					}

					dl:nth-of-type(2) {
						margin-left: 10px;
					}
				}
			}
		}

		.top-right {
			margin: 16px 0;

			.right-1 {
				span:nth-of-type(1) {
					color: #AFAFAF;

					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}

				span:nth-of-type(2) {
					padding-left: 20px;
					padding-right: 5px;
					color: #FFF;
					font-feature-settings: 'clig' off, 'liga' off;

					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}

			.right-2 {
				dl {
					width: 100px;
					cursor: pointer;
					margin: 0;
					padding: 2px 0;
					border-radius: 100px;
					border: 0.5px solid rgba(255, 255, 255, 0.20);
					background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.24) 100%);

					dt {
						display: inline-block;

						img {
							width: 12px;
							height: 12px;
						}
					}

					dd {
						display: inline-block;
						color: #FFF;
						font-feature-settings: 'clig' off, 'liga' off;

						font-size: 10px;
						font-style: normal;
						font-weight: 400;
						margin: 0;
					}
				}

				dl:nth-of-type(2) {
					margin-top: 10px;
				}
			}


		}

		.team-cardcontent {
			padding: 10px 10px 0;
			border-radius: 12px;
			border: 1px solid rgba(255, 255, 255, 0.20);
			background: linear-gradient(0deg, rgba(215, 204, 246, 0.40) -8.91%, rgba(19, 8, 48, 0.00) 45.33%), radial-gradient(75.76% 75.76% at 50% 10.33%, rgba(0, 0, 0, 0.40) 0%, rgba(4, 1, 19, 0.00) 39.26%, rgba(61, 84, 202, 0.40) 100%);

			.progress {
				flex: 1;
				margin-right: 20px;
				max-width: 540px;

				.ranking {
					width: 72px;
					height: 72px;
					flex-shrink: 0;
					/* 06dp */

					background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/Base.png') no-repeat;
					background-size: 100% 100%;
					position: relative;
					z-index: 9;

					.ranking-box {
						position: absolute;
						left: 50%;
						top: 50%;

						transform: translate(-50%, -50%);
						width: 43.855px;
						height: 47.25px;
						background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/baseimg.png') no-repeat;
						background-size: 100% 100%;
						margin: 0;

						dt {
							padding-top: 10px;
							text-align: center;
							font-feature-settings: 'clig' off, 'liga' off;

							font-size: 16px;
							font-style: normal;
							font-weight: 700;
							line-height: 120%;
							/* 19.2px */
							text-transform: uppercase;

							background: var(--Linear, linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%));
							background-clip: text;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;

						}

						dd {
							color: #4A4A4A;

							font-size: 8px;
							font-style: normal;
							font-weight: 274;
							line-height: normal;
						}
					}
				}

				.ranking-right {
					margin-left: -50px;
					width: 100%;
					flex: 1;
					height: 40px;
					min-width: 220px;
					padding-right: 15%;
					background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/ranking-right.png') no-repeat;
					background-size: 100% 100%;
					position: relative;
					z-index: 2;
					display: flex;
					align-items: center;
					justify-content: flex-end;

					&::after {
						content: "";
						display: block;
						position: absolute;
						z-index: 1;
						width: 110%;
						height: 51.677px;
						flex-shrink: 0;
						background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/ranking-right-bg.png') no-repeat;
						background-size: 100% 100%;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);

					}

					span:nth-of-type(1) {
						color: #FFF;

						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					span:nth-of-type(2) {
						padding-left: 11px;
						color: #FFF;

						font-size: 20px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
					}
				}

			}

			.team-name {
				dl {
					margin: 0;

					dt {
						margin: 0;
						color: #AFAFAF;

						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					dd {
						padding-left: 12px;
						margin: 0;
						color: #FFF;
						font-feature-settings: 'clig' off, 'liga' off;

						font-size: 20px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;

					}
				}

				span {
					display: inline-block;
					padding: 4px 8px;
					border-radius: 100px;
					border: 0.5px solid rgba(255, 255, 255, .6);

					color: #FFF;
					font-feature-settings: 'clig' off, 'liga' off;

					font-size: 8px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}

			.team-size {
				margin: 0;

				dt {
					margin: 0;
					color: #AFAFAF;

					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}

				dd {
					padding-left: 12px;
					margin: 0;

					span:nth-of-type(1) {
						color: #FFF;

						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					span:nth-of-type(2) {
						color: #959595;

						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}

			.list-box {
				transition: all .2s;
				border: 1px solid rgba(255, 255, 255, 0);

				.title {
					border-top: 1px solid rgba(255, 255, 255, 0.12);
					padding: 12px 0 20px;
					text-align: center;
					position: relative;
					cursor: pointer;

					p {
						margin: 0;
						color: #FFF;
						font-feature-settings: 'clig' off, 'liga' off;

						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					.anticon {
						position: absolute;
						left: 50%;
					}

					.anticon-up {
						top: 8px;
						display: none;
					}

					.anticon-down {
						bottom: 8px;
					}

				}

				.ul-box {
					height: 0px;
					padding: 0;
					overflow: hidden;
					transition: all .2s;

					ul {
						margin: 0;
						border-top: 1px solid rgba(255, 255, 255, 0);
						border-bottom: 1px solid rgba(255, 255, 255, 0);
						height: 229px;

						.ul-head {
							display: flex;
							justify-content: space-around;

							span {
								color: #E8E8E8;

								font-size: 12px;
								font-style: normal;
								font-weight: 274;
								line-height: normal;
							}
						}

						li:nth-of-type(1) {
							span {
								background: var(--Linear, linear-gradient(131deg, #C4923E -1.25%, #E4BE68 28.42%, #F8DA83 53.61%, #FEE07E 63.92%, #F3C433 82.64%, #E0AE2D 93.52%));
								background-clip: text;
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}
						}

						li {
							width: 100%;
							display: grid;
							grid-template-columns: 1fr 3fr 1fr;
							text-align: center;
							margin: 16px 0;

							span {
								color: #E8E8E8;
								text-align: center;

								font-size: 12px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
							}
						}
					}

					p {
						margin-bottom: 0;
						padding: 12px;
						display: flex;
						justify-content: flex-end;

					}
				}


			}

			.list-show {
				margin-bottom: 40px;
				border-radius: 15px;
				backdrop-filter: blur(10px);
				border: 1px solid rgba(255, 255, 255, 0.2);
				background: rgba(255, 255, 255, 0.04);
				box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.20);

				.title {
					border: none;
					padding: 20px 0 10px;

					.anticon-up {
						display: block;
					}

					.anticon-down {
						display: none;
					}
				}

				.ul-box {
					height: 275px;

					ul {
						border-top: 1px solid rgba(255, 255, 255, 0.12);
						border-bottom: 1px solid rgba(255, 255, 255, 0.12);
						padding: 20px 0;
					}
				}

			}


		}

		.btn-box-card {
			margin-top: 15px;

			.ant-btn {
				width: 100%;
				height: 44px;
				border-radius: 6px;
				border: unset;
				background: var(--2, linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%));
			}

			.ant-btn+.ant-btn {
				margin-top: 10px;
			}

			.acquisition {
				text-align: center;

				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 16px;
				/* 114.286% */
				color: #E4BE68 !important;

				background: unset;
			}
		}


		@media (min-width: 1200px) and (max-width: 1440px) {
			display: none;
		}
	}

	.no-list {
		padding: 100px 0;
		text-align: center;


		color: #FFF;
		text-align: center;

		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;


		@screen md {
			padding: 30px 0;
			font-size: 16px;
		}
	}

	.noBridge-box {
		.noBridge {
			min-width: 860px;

			p {
				padding-top: 50px;

				span:nth-of-type(1) {

					font-size: 50px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}

				span:nth-of-type(2) {
					padding-left: 10px;
					color: #FFF;

					font-size: 40px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
			}

			.ant-btn {
				margin: 80px 0 50px;
				width: 480px;
				height: 77px;
				justify-content: center;
				align-items: center;
				gap: 10px;
				border-radius: 12px;
				background: var(--2, linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%));

				border: none;
				color: #FFF;
				text-align: center;

				font-size: 32px;
				font-style: normal;
				font-weight: 700;
				line-height: 140%;
				/* 44.8px */
			}
		}

		@screen md {
			.noBridge {
				min-width: unset;

				textarea {
					font-size: 16px;
				}

				.ant-btn {
					width: 100%;
					font-size: 14px;
					height: 44px;
					padding: 0 24px;

					img {
						width: 14px;
						height: 14px;
						margin-top: 0px;
						margin-right: 5px;
					}
				}
			}

		}
	}

	.fsTwitter-box {

		.noBridge {
			min-width: 860px;
		}

		p {

			span:nth-of-type(1) {

				font-size: 50px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}

		}

		textarea {
			margin: 15px 0;
			padding: 10px;
			background: unset;
			height: auto;
			color: #fff;
			font-size: 25px;
			border: 1px solid rgba(255, 255, 255, 0.4);
		}

		.ant-btn {
			margin: 20px 0 0px;
			width: 480px;
			height: 77px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			border-radius: 12px;
			background: var(--2, linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%));

			border: none;
			color: #FFF;
			text-align: center;

			font-size: 32px;
			font-style: normal;
			font-weight: 700;
			line-height: 140%;

			img {
				width: 26px;
				height: 26px;
				margin-top: 5px;
				margin-right: 15px;
			}

			/* 44.8px */
		}

		@screen md {
			.noBridge {
				min-width: unset;
			}

			p {
				span:nth-of-type(1) {
					font-size: 30px;
				}
			}

			textarea {
				font-size: 16px;
			}

			.ant-btn {
				width: unset;
				font-size: 14px;
				height: 44px;
				padding: 0 24px;

				img {
					width: 14px;
					height: 14px;
					margin-top: 0px;
					margin-right: 5px;
				}
			}
		}
	}

	.set-team-name {
		.set-team-name-box {
			padding: 40px 40px 0;

			h1 {
				color: rgba(255, 255, 255, 0.80);
				text-align: center;
				font-feature-settings: 'clig' off, 'liga' off;
				font-size: 32px;
				font-style: normal;
				font-weight: 700;
				line-height: 32px;
				/* 100% */
			}

			h2 {
				color: #FFF;
				text-align: center;
				font-feature-settings: 'clig' off, 'liga' off;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px;
				/* 100% */
			}

			.input-box {
				margin: 60px 0 40px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.40);

				input {
					width: 100%;
					padding: 24px;
					background: unset;
					border: unset;

					color: #FFF;
					text-align: center;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px;
					/* 100% */

					background: none;
					border: none;
					-webkit-tap-highlight-color: rgba(255, 0, 0, 0);

					&::focus {
						border-color: unset;
						box-shadow: unset;
					}
				}

			}

			.ant-btn {
				width: 100%;
				height: 58px;

				border-radius: 12px;
				background: var(--2, linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%));

				border: none;
				color: #FFF;
				text-align: center;

				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				/* 44.8px */
			}

			.ant-btn+.ant-btn {
				margin-top: 15px;
				background: #242424;
			}
		}

		@screen md {
			.set-team-name-box {
				padding: 20px 20px 0;
			}
		}
	}
</style>