<template>
	<div class="m-pagination flex">
		<a-icon type="left" :class="{'dis':index ==1 }" :disabled="index ==1" @click="reduce" />
		<div class="center">
			<span>{{index}}</span>
			<span>of</span>
			<span>{{pageCount}}</span>
		</div>
		<a-icon type="right" :class="{'dis':index == pageCount }" :disabled="index == pageCount" @click="increase" />
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	export default {
		name: 'Name',
		data() {
			return {
				index: 1,
			}
		},
		props: {
			total: {
				type: Number,
				default: 0
			},
			pageIndex: {
				type: Number,
				default: 1
			},
			pageCount: {
				type: Number,
				default: 1
			},
		},
		computed: {

		},
		mounted() {

		},
		watch: {
			index(val) {
				this.$emit('onChange', this.index)
			}
		},
		methods: {
			reduce() {
				if (this.index > 1) {
					this.index--
				}
			},
			increase() {
				if (this.index < this.pageCount) {
					this.index++
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-pagination {
		color: #FFF;
		
		font-size: 14px;
		font-style: normal;
		font-weight: 400;



		.center {
			padding: 0 20px;
			span{
				&::selection{
					background: unset;
				}
			}
			span:nth-of-type(2) {
				padding: 0 5px;
			}
		}

		.dis {
			color: rgba(145, 158, 171, 0.8);
		}
	}
</style>