<template>
	<div class="teams ">
		<TeamCard :teamleader="addressinfo.teamleader"></TeamCard>
		<TeamCard :teamleader="address"></TeamCard>
		
		<div class="team-ranking">
			<div class="title">
				Team Ranking
			</div>
			<div class="list-box list-show">
				<div class="scroll-box">
					<div class="ul-head ">
						<span class="md:hidden xl:block l:block xm:block">Ranking</span>
						<span>Team Name</span>
						<span>Team Size</span>
						<span>Total Points</span>
						<span class="md:hidden xl:block l:block xm:block">Team Details</span>
						<span class="md:block xl:hidden l:hidden xm:hidden">Details</span>
					</div>
					<ul>
						<li class="" v-for="(item,idx) in teamList" :key="idx">
							<span class="md:hidden xl:block l:block xm:block">{{idx+1}}</span>
							<span>{{item.teamname}}</span>
							<span>{{item.membercount}}/25</span>
							<span>{{item.teamscore | formatMoney }}</span>

							<a-popover trigger="click" v-model="item.close">
								<template slot="content">
									<div class="team-popover-list">
										<p class="flex-between">
											<span>{{item.teamname}}</span>
											<a-icon class="iconguanbi" type="close" @click="item.close = false" />
										</p>
										<TeamDetails :teamleader="item.teamleader"></TeamDetails>
									</div>
								</template>
								<span style="cursor: pointer;">more +</span>
							</a-popover>
						</li>
					</ul>
				</div>
				<div class="more flex-end ">
					<mPagination :pageCount="pageCount" @onChange="onChange" />
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import mPagination from '@/components/m-pagination/m-pagination.vue'
	import modal from '@/components/modal.vue'
	import TeamCard from '@/components/dashboard/team-card.vue'
	import TeamDetails from '@/components/dashboard/team-details.vue'
	import { getteamlist, getbstassert } from '@/utils/active.api'
	import { mapState } from 'vuex'
	export default {
		name: 'teams',
		components: { modal, TeamCard, TeamDetails, mPagination },
		data() {
			return {
				pageCount: 1,
				pageIndex: 1,

				teamList: [],
			}
		},
		computed: {
			...mapState(['addressinfo', 'address']),
		},
		mounted() {
			getbstassert();
			this.init();
		},
		methods: {
			async init() {
				const { succes, list, pageCount, pageIndex } = await getteamlist({
					pageSize: 5,
					pageIndex: this.allTeamPageIndex,
				})
				this.pageCount = pageCount
				this.pageIndex = pageIndex
				this.teamList = list;

			},
			onChange(val) {
				this.pageIndex = val;
				this.init();
			},



		}
	}
</script>

<style lang="scss" scoped>
	.teams {
		padding: 80px 0;




		.team-ranking {
			padding: 40px;
			border-radius: 20px;
			border: 1px solid rgba(255, 255, 255, 0.20);
			background: url(<path-to-image>), lightgray 50% / cover no-repeat, linear-gradient(0deg, rgba(215, 204, 246, 0.20) -8.91%, rgba(19, 8, 48, 0.00) 45.33%), radial-gradient(75.76% 75.76% at 50% 10.33%, rgba(0, 0, 0, 0.20) 0%, rgba(4, 1, 19, 0.00) 39.26%, rgba(61, 84, 202, 0.20) 100%), #000;

			.title {
				color: #FFF;

				font-size: 40px;
				font-style: normal;
				font-weight: 590;
				line-height: 40px;
				/* 100% */
				letter-spacing: 0.4px;

				margin-bottom: 80px;
			}

			.list-box {
				border-radius: 15px;
				backdrop-filter: blur(10px);
				border: 1px solid rgba(255, 255, 255, 0.2);
				background: rgba(255, 255, 255, 0.04);
				box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.20);

				.ul-head {
					padding: 20px;
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
					text-align: center;

					span {
						color: #E8E8E8;

						font-size: 24px;
						font-style: normal;
						font-weight: 274;
						line-height: normal;
					}
				}

				ul {
					margin: 0;
					border-bottom: 1px solid rgba(255, 255, 255, 0.12);
					border-top: 1px solid rgba(255, 255, 255, 0.12);

					li {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
						text-align: center;
						margin: 22px 0;

						span {
							color: #FFF;
							font-feature-settings: 'clig' off, 'liga' off;
							font-size: 24px;
							font-weight: 400;
							line-height: normal;
						}
					}

				}

				.more {
					padding: 20px;
				}

			}




		}

		@screen md {
			min-width: unset;
			padding: 10px 0;

			.team-ranking {
				padding: 12px;

				.title {
					font-size: 16px;
					margin-bottom: 20px;
				}

				.list-box {

					.scroll-box {
						overflow-y: scroll;
					}

					.ul-head {
						min-width: 450px;
						padding: 12px 0;
						display: grid;
						grid-template-columns: 1fr 1fr 1fr 1fr;
						text-align: center;

						span {
							font-size: 14px;
						}
					}

					ul {
						min-width: 450px;
						border-bottom: 1px solid rgba(255, 255, 255, 0.12);
						border-top: 1px solid rgba(255, 255, 255, 0.12);

						li {
							display: grid;
							grid-template-columns: 1fr 1fr 1fr 1fr;
							text-align: center;
							margin: 22px 0;

							span {
								font-size: 14px;
							}
						}

					}

					.more {
						padding: 20px;
					}

				}




			}
		}
	}

	.team-popover-list {
		width: 430px;
		border-radius: 15px;
		border: 1px solid rgba(255, 255, 255, 0.20);
		background: rgba(6, 8, 22, 0.60);
		box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.20);
		backdrop-filter: blur(10px);

		p {
			padding: 12px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.12);

			span {
				color: #FFF;
				font-feature-settings: 'clig' off, 'liga' off;

				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			.iconguanbi {
				color: #fff;
			}
		}


		@screen md {
			margin: 0 auto;
			width: 95%;
		}

	}
</style>