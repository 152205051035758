<template>
	<div class="personal ">
		<div class="personal-card-box">
			<div class="personal-card" :class="{'noHave':setterv1<=0}" @click="openClaim(medalList['setterv1'])">
				<div class="img-box">
					<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv1.png" alt="" />
				</div>
				<p>Early Settlers Lv.1</p>
			</div>
			<div class="personal-card " :class="{'noHave':setterv2<=0}" @click="openClaim(medalList['setterv2'])">
				<div class="img-box">
					<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv2.png" alt="" />
				</div>
				<p>Early Settlers Lv.2</p>
			</div>
			<div class="personal-card" :class="{'noHave':setterv3<=0}" @click="openClaim(medalList['setterv3'])">
				<div class="img-box">
					<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv3.png" alt="" />
				</div>
				<p>Early Settlers Lv.3</p>
			</div>
		</div>
		<div class="flex-center" v-if="addressinfo['totalincome']<500">
			<a-button type="primary">
				<span>Bridge ${{ setterNum}}  to unlock the next level medal</span>
			</a-button>
		</div>

		<ClaimModal :item="popupItem" :visible="showClaim" @closeClaim="closeClaim" :btnShow="false" :route="'/allMedalWall?id='+popupItem['ID']"></ClaimModal>
	</div>
</template>

<script>
	import medalList from '@/views/allMedalWall/medalList.js'
	import { getbstassert, getaddressinfo } from '@/utils/active.api'
	import { mapState } from 'vuex'
	import ClaimModal from '@/components/allMedalWall/ClaimModal.vue';
	export default {
		name: 'personal',
		components: { ClaimModal },
		data() {
			return {
				setterv1: false,
				setterv2: false,
				setterv3: false,

				popupItem: {},
				showClaim: false,
			}
		},
		computed: {
			...mapState(['addressinfo', 'address', 'sbtMedal']),
			setterNum() {
				const nowIncome = Number(this.addressinfo['totalincome']) || 0;
				if (nowIncome >= 500) return 0
				let incomeArr = [10, 100, 500, nowIncome];
				let a = incomeArr.sort().lastIndexOf(nowIncome)
				let num = incomeArr[a + 1] - nowIncome
				return num
			},
			medalList() {
				return medalList
			},
		},
		mounted() {
			// 
			this.init()
		},
		methods: {
			async init() {
				await getaddressinfo()

				const { success, data: { setterv1, setterv2, setterv3 } } = await getbstassert()
				if (success) {
					this.setterv1 = Number(setterv1);
					this.setterv2 = Number(setterv2);
					this.setterv3 = Number(setterv3);
				}

			},
			openClaim(item) {
				this.popupItem = item;
				this.showClaim = true
			},
			closeClaim() {
				this.popupItem = {};
				this.showClaim = false
			},
		}

	}
</script>

<style lang="scss" scoped>
	.personal {
		padding: 80px 0;

		.personal-card-box {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.personal-card {

				cursor: pointer;
				position: relative;
				padding: 20px;
				flex: 1;
				max-width: 420px;
				text-align: center;
				border-radius: 20px;
				border: 1px solid rgba(255, 255, 255, 0.20);
				background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/Frame-bg.png');
				background-position: left top;
				background-size: cover;
				box-shadow: 12px 12px 20px 0px rgba(26, 33, 67, 0.40);

				.img-box {
					width: 100%;

					img {
						width: 100%;
					}

				}

				p {
					padding-top: 30px;
					color: #FFF;

					font-size: 28px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
			}

			.personal-card+.personal-card {
				margin-left: 20px;
			}

			.noHave {
				&::after {
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 2;
					background: rgba(1, 1, 1, 0.4);
				}
			}



		}

		.ant-btn {
			margin: 50px auto;
			padding: 5px 30px;
			height: 60px;

			font-size: 20px;
			font-weight: 700;
			line-height: 20px;
			letter-spacing: 0em;
			text-align: right;
			border: none;
			border-radius: 12px;
			background: var(--2, linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%));

		}

		@screen md {
			padding: 40px 0;

			.personal-card-box {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;

				.personal-card {
					width: 100%;
					margin-bottom: 20px;
					margin-left: 0 !important;
					display: flex;
					align-items: center;

					.img-box {
						max-width: 90px;
					}

					p {
						color: #FFF;

						font-size: 20px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
					}
				}
			}

			.ant-btn {
				max-width: 100%;
				margin: 20px 0;
				height: 42px;
				font-size: 14px;
			}

		}
	}
</style>