<template>
	<div class="list-box">
		<ul>
			<li class="ul-head ">
				<span>Ranking</span>
				<span>Member`s Wallet Address</span>
				<span>Points Earned</span>
			</li>
			<li class="" v-for="(item,idx) in teammember" :key="idx">
				<span>{{idx+1}}</span>
				<span>{{idx==0?'Captain ':'' }}{{item.useraddress | addressFilter }}</span>
				<span>{{item.totalscore | formatMoney}}</span>
			</li>
		</ul>

		<p class="flex-end">
			<mPagination :pageCount="pageCount"  @onChange="onChange" />
		</p>
	</div>
</template>

<script>
	import { getteaminfo, createTeam, getteamlist, getteammember, getbstassert } from '@/utils/active.api'
	import { mapState } from 'vuex'
	import mPagination from '@/components/m-pagination/m-pagination.vue'
	export default {
		name: 'Name',
		components: { mPagination },
		data() {
			return {
				pageCount: 1,
				pageIndex: 1,
				teammember: [],
			}
		},
		computed: {
			...mapState(['addressinfo', 'address']),
		},
		props: {
			teamleader: {
				type: String,
				default: ''
			},
		},
		watch: {
			address(newValue, oldValue) {
				this.init();
			},
			teamleader(val) {
				console.log(121213)

			}
		},
		computed: {

		},
		mounted() {
			this.init();
		},
		methods: {
			onChange(val) {
				this.pageIndex = val;
				this.getteammember();
			},
			async init() {
				this.getteammember();
			},
			//团队成员
			async getteammember() {
				if (!this.teamleader) return
				const { success, list, totalCount, pageCount } = await getteammember({
					teamleader: this.teamleader,
					pageSize: 5,
					pageIndex: this.pageIndex,
				})
				this.pageCount = pageCount
				this.teammember = list;
			},


		}
	}
</script>

<style lang="scss" scoped>
	.list-box {
		min-width: 375px;
		ul {
			li:nth-of-type(2) {
				span {
					background: var(--Linear, linear-gradient(131deg, #C4923E -1.25%, #E4BE68 28.42%, #F8DA83 53.61%, #FEE07E 63.92%, #F3C433 82.64%, #E0AE2D 93.52%));
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}

			.ul-head {
				display: grid;
				grid-template-columns: 60px 3fr 100px;
				text-align: center;

				span {
					color: #E8E8E8;
					
					font-size: 14px;
					font-style: normal;
					font-weight: 374;
					line-height: normal;
				}

			}

			li {
				display: grid;
				grid-template-columns: 60px 3fr 100px;
				text-align: center;
				margin: 6px 0;
				padding: 8px 10px;

				span {
					color: #E8E8E8;
					text-align: center;
					
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}

		p {
			margin: 0;
			padding: 12px;
			border-top: 1px solid rgba(255, 255, 255, 0.12);

		}
	}
</style>
<style>
	.ant-pagination {
		.anticon {
			color: #fff;
		}

		.ant-pagination-item-link {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.ant-pagination-item {
			a {
				color: #FFF;
				
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
			}
		}

		.ant-pagination-item-active {
			a {
				color: #111;
			}
		}



	}
</style>