<template>
	<div class="Dashboard">
		<Header></Header>
		<div class="Dashboard-box  md:px-12">
			<div class="Dashboard-head flex-between">
				<div class="left flex">
					<dl :class="isTab == 1 ? 'active' : ''" @click="isTab = 1">
						<dt>
							<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/person.png" alt="" srcset="" />
						</dt>
						<dd> Personal</dd>
					</dl>
					<dl :class="isTab == 2 ? 'active' : ''" @click="isTab = 2">
						<dt><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/groups.png" alt="" srcset="" /></dt>
						<dd>Teams</dd>
					</dl>
				</div>
				<div class="right">
					<dl style="cursor: pointer;" @click="toBridge">
						<dd>Earn points</dd>
					</dl>
					<dl style="cursor: pointer;" @click="toGuidance">
						<dd>Guidance</dd>
					</dl>
				</div>
			</div>
			<div class="">
				<personal v-if="isTab == 1"></personal>
				<teams v-if="isTab == 2"></teams>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'

import personal from './personal.vue'
import teams from './teams.vue'

import { mapState } from 'vuex'
export default {
	name: 'Faucet',
	components: {
		Header,
		Footer,
		Breadcrumb,
		personal,
		teams,
	},
	data() {
		return {
			isTab: 2,
			isShow: false,
			routes: [{
				path: '/',
				breadcrumbName: 'Home',
			},

			{
				path: '/Dashboard',
				breadcrumbName: 'Dashboard',
			},
			],
		}
	},
	computed: {
		...mapState(['isConnect', 'address']),
	},
	mounted() {

	},
	methods: {
		toBridge() {
			window.open('https://bridge.wodrpc.org/#/index')
		},
		toGuidance() {
			const routeUrl = this.$router.resolve({
				path: "/guidance",
			});

			window.open(routeUrl.href, "_blank");
		}

	}
}
</script>

<style lang="scss" scoped>
.Dashboard {
	position: relative;
	background-color: #000000;
	color: #ffffff;

	.Dashboard-box {
		padding: 14px 80px;


		.Dashboard-head {
			padding: 60px 0 12px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.40);

			.left {
				display: flex;
				gap: 30px;
				align-items: center;
				dl {
					/* margin-right: 30px; */
					margin: 0;
					padding: 8px 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 8px;
					background: rgba(255, 255, 255, 0.06);
					transition: all 1s;
					cursor: pointer;

					dt {
						img {
							width: 24px;
							height: 24px;
						}
					}

					dd {
						padding-left: 16px;
						margin: 0;
						color: #FFF;
						font-feature-settings: 'clig' off, 'liga' off;
						
						font-size: 24px;
						font-style: normal;
						font-weight: 510;
						line-height: normal;
					}
				}

				.active {
					background: linear-gradient(270deg, #203DFD 0%, #34C5F3 100%);
				}
			}

			.right {
				display: flex;
				align-items: center;
				gap: 12px;
				dl {
					margin: 0;
					padding: 10px 40px;
					font-size: 20px;
					line-height: 16px;
					border-radius: 100px;
					/* border: 1px solid rgba(255, 255, 255, 0.20); */
					background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.24) 100%);

					dd {
						margin: 0;
					}
				}
			}
		}

		@screen md {
			padding-bottom: 0;

			.Dashboard-head {
				border-bottom: unset;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;

				.left {
					gap: 16px;
					dl {
						/* margin-right: 16px; */
						padding: 8px 20px;
						border-radius: 100px;

						dt {
							img {
								width: 24px;
								height: 24px;
							}
						}

						dd {

							font-size: 16px;

						}
					}

					.active {
						background: linear-gradient(270deg, #203DFD 0%, #34C5F3 100%);
					}
				}

				.right {
					width: 100%;
					padding-top: 14px;
					border-top: 1px solid rgba(255, 255, 255, 0.40);
					@screen md {
						border-top: none;
					}

					dl {
						margin: 0;
						display: inline-block;
						padding: 4px 20px;

						dd {
							margin: 0;
							font-size: 16px;
						}
					}
				}
			}

		}
	}


}
</style>